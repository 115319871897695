<template>
  <div class="">
    <div class="flex items-center">
      <div class=" w-full rounded-10 h-auto bg-white flex p-6">
        <div class="w-full text-left">
          <div class="text-c24 text-60 font-c5">
            Stock produits
          </div>
          <div class="mt-6 flex">
            <div class="w-1/3 bloc1 flex items-center p-6">
              <icon
                :data="icons.bag"
                height="80"
                width="80"
                class=""
                original
              />
              <div class="w-full text-left text-white ml-4">
                <div class="text-c15">
                  Verres
                </div>
                <div class="text-c26 font-c5">
                  5800
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-center mt-5">
      <div
        class=" w-1/2 rounded-10 h-119 bg-white flex p-6 pr-8 opn cursor-pointer"
        @click="goto"
      >
        <div class="w-1/2 text-left">
          <div class="text-c24 text-60 font-c5">
            Réalisations (3)
          </div>
          <div class="text-c24 font-c5 mt-2">
            6 266 000 <span class="text-90">FCFA</span>
          </div>
        </div>

        <div class="w-1/2 flex items-center justify-end porte">
          <div class="text-oho-blue text-c18 font-c4">
            Portefeuille
          </div>
          <icon
            :data="icons.arrow"
            height="20"
            width="20"
            class="ml-4"
            original
          />
        </div>
      </div>

      <div class="w-1/2 ml-8 rounded-10 h-119 bg-white flex p-6">
        <div class="w-full text-left">
          <div class="text-c24 text-60 font-c5">
            CNLNS
          </div>
          <div class="text-c24 font-c5 mt-2">
            24
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import bag from '../../../assets/icons/bag.svg'
import portefeuille from '../../../assets/icons/portefeuille.svg'
import dollard from '../../../assets/icons/dollard.svg'

export default {
  name: "Index",

  data () {
    return {
      icons: {
        arrow,
        bag,
        portefeuille,
        dollard
      }
    }
  },
  methods: {
    goto(){
      this.$emit('oga', true)
    }
  },
}
</script>

<style lang="scss" scoped>
  .opn {
    .porte{
      display: none;
    }
  }

  .opn:hover {
    .porte{
      display: flex;
    }
  }
  .bloc1{
    background: linear-gradient(119.87deg, #6171FF 26.76%, #4328E6 75.96%);
    border-radius: 9.13512px;
    height: 102.31px;
  }
</style>
