<template>
  <div class="p-10 pr-16">
    <div v-if="!activePortefeuille">
      <div class="flex items-center">
        <div
          class="w-2/3 text-left text-c40 font-c6"
          @click="activeDetail = false"
        >
          Tableau de bord
        </div>
      </div>

      <div
        class="mt-12 w-full"
        @click="activeDetail = false"
      >
        <stock @oga="activePortefeuille = true" />
      </div>

      <div
        class="mt-12 w-full"
        @click="activeDetail = false"
      >
        <turnover />
      </div>

      <div
        class="mt-12 w-full"
        @click="activeDetail = false"
      >
        <sell-stats />
      </div>
    </div>
  </div>
</template>

<script>
import add from '../../assets/icons/add.svg'
import stock from '../../component/cdevs/dashboard/stockProduct'
import turnover from '../../component/cdevs/dashboard/turnover'
import sellStats from '../../component/cdevs/dashboard/sellStat'

export default {
  name: "Index",

  components: {
    stock,
    turnover,
    sellStats
  },

  data () {
    return {
      activePortefeuille: false,
      activeDetail: false,
      optionAdd: ['Devis', 'Rendez-vous', 'Plainte', 'Rendez-vous', 'Appel', 'Note'],
      icons: {
        add
      }
    }
  },
  mounted() {
  },
  methods: {
    retourClick(){
      this.activeDetail =! this.activeDetail
    }
  }
}
</script>

<style scoped>

</style>
